import { motion, AnimatePresence } from 'framer-motion';
import { useState, useEffect, createContext, useContext } from 'react';
import { HiCommandLine } from 'react-icons/hi2';
import { BrowserRouter as Router, Routes, Route, Link, useParams, useLocation } from 'react-router-dom';
import { FiGithub, FiLinkedin, FiMail } from 'react-icons/fi';
import { BsTwitterX } from 'react-icons/bs';
import { FaSquareThreads } from 'react-icons/fa6';
import profilePic from './images/profile.png';
import profilePicLight from './images/profile-light.jpg';
import profilePicGhibli from './images/prateek-ghibli.png';
import ProjectCard from './components/ProjectCard';
import Experience from './components/Experience';
import { Analytics } from '@vercel/analytics/react';
import Nav from './components/Nav';
import Footer from './components/Footer';
import { useMetaPreviews } from './hooks/useMetaPreviews';
import CommandPalette from './components/CommandPalette';
import { PiLinkSimple, PiArrowRightThin } from "react-icons/pi";
import { EasterEggProvider, useEasterEggs } from './contexts/EasterEggContext';
import { ThemeProvider, useTheme, THEMES } from './contexts/ThemeContext';
import BlogList from './components/BlogList';
import BlogPost from './components/BlogPost';
import { getAllPosts } from './utils/posts';

// Projects
const PROJECTS = [
  { name: 'Berlin Emergency Tracker', link: 'https://berlin.prateekkeshari.com' },
  { name: 'Radiocast', link: 'https://radiocast.co' },
  { name: 'Time', link: 'https://time.prateekkeshari.com' },
  { name: 'Scoop', link: 'https://scoop.prateekkeshari.com' },
  { name: 'Mockmint', link: 'https://mockmint.prateekkeshari.com' },
  { name: 'Peek AI', link: 'https://prateekkeshari.gumroad.com/l/peek' }
];

// Create font context
export const FontContext = createContext();

// Create a hook for using font
export const useFont = () => useContext(FontContext);

// Add this near the top of your App.js, after the imports
const shimmerClass = `
  hover:animate-shimmer bg-clip-text hover:text-transparent 
  hover:bg-[linear-gradient(110deg,#000000,30%,#ffffff,45%,#000000,55%,#ffffff,70%,#000000)] 
  dark:hover:bg-[linear-gradient(110deg,#ffffff,30%,#1e293b,45%,#ffffff,55%,#1e293b,70%,#ffffff)]
  light:hover:bg-[linear-gradient(110deg,#000000,30%,#ffffff,45%,#000000,55%,#ffffff,70%,#000000)]
  hover:bg-[length:300%_100%] transition-all duration-300 ease-in-out
`;

const BlogPostWrapper = () => {
  const { slug } = useParams();
  const posts = getAllPosts();
  const post = posts.find(p => p.slug === slug);
  return <BlogPost post={post} />;
};

// Main App component
function App() {
  // Add fontFamily state
  const [fontFamily, setFontFamily] = useState(() => {
    const savedFont = localStorage.getItem('font');
    return savedFont || 'default';
  });

  return (
    <EasterEggProvider>
      <ThemeProvider>
        <Router>
          <FontContext.Provider value={{ fontFamily, setFontFamily }}>
            <AppContent />
          </FontContext.Provider>
        </Router>
      </ThemeProvider>
    </EasterEggProvider>
  );
}

// Create a wrapper component to use location and share context
function AppContent() {
  const { fontFamily } = useContext(FontContext);
  const location = useLocation();
  const { theme, isDarkMode, isGhibliMode, toggleTheme, setTheme } = useTheme();
  const [activePreview, setActivePreview] = useState(null);
  const [gygPreviewData, setGygPreviewData] = useState(null);
  const [isCommandPaletteOpen, setIsCommandPaletteOpen] = useState(false);
  const [isSocialExpanded, setIsSocialExpanded] = useState(false);
  const [showEasterEgg, setShowEasterEgg] = useState(false);
  const { discoverEgg, discoveredEggs } = useEasterEggs();
  const [posts] = useState(getAllPosts());

  // Then use it in hooks
  const { previews } = useMetaPreviews(PROJECTS);

  // Add state for tracking Konami code
  const [konamiSequence, setKonamiSequence] = useState([]);
  const konamiCode = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'];

  useEffect(() => {
    const handleKeyDown = (e) => {
      const newSequence = [...konamiSequence, e.key];
      if (newSequence.length > konamiCode.length) {
        newSequence.shift();
      }
      setKonamiSequence(newSequence);

      if (newSequence.join(',') === konamiCode.join(',')) {
        if (!discoveredEggs.KONAMI_CODE) {
          discoverEgg('KONAMI_CODE');
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [konamiSequence, discoveredEggs, discoverEgg, konamiCode]);

  // Improved keyboard shortcuts handler
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Command palette trigger
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        e.preventDefault();
        setIsCommandPaletteOpen(true);
        if (!discoveredEggs.includes('COMMAND_PALETTE')) {
          discoverEgg('COMMAND_PALETTE');
        }
      }
      
      // Theme toggle trigger
      if (e.key.toLowerCase() === 'l' && !isCommandPaletteOpen && !e.metaKey && !e.ctrlKey) {
        e.preventDefault();
        toggleTheme();
        if (!discoveredEggs.includes('THEME_KEYBOARD')) {
          discoverEgg('THEME_KEYBOARD');
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isCommandPaletteOpen, discoveredEggs, discoverEgg, toggleTheme]);

  // Add fetch for GetYourGuide
  useEffect(() => {
    const fetchGygPreview = async () => {
      try {
        const response = await fetch('https://api.microlink.io?url=https://www.getyourguide.com');
        const data = await response.json();
        setGygPreviewData(data.data);
      } catch (error) {
        console.error('Error fetching GYG preview:', error);
      }
    };
    fetchGygPreview();
  }, []);

  // Easter egg handler
  const handleBuildClick = () => {
    setShowEasterEgg(true);
    setTimeout(() => {
      setShowEasterEgg(false);
    }, 2000);
  };

  // Update the useEffect for font storage
  useEffect(() => {
    localStorage.setItem('font', fontFamily);
  }, [fontFamily]);

  // Improved social expander trigger - only play sound here
  const handleSocialExpand = () => {
    if (!isSocialExpanded && !discoveredEggs.includes('SOCIAL_EXPANDER')) {
      discoverEgg('SOCIAL_EXPANDER');
    }
    setIsSocialExpanded(!isSocialExpanded);
  };

  // Improved version shimmer trigger
  const handleVersionShimmer = () => {
    if (!discoveredEggs.includes('VERSION_SHIMMER')) {
      discoverEgg('VERSION_SHIMMER');
    }
  };

  // Dust sprites for Ghibli theme
  const renderGhibliDustSprites = () => {
    if (!isGhibliMode) return null;
    
    return (
      <>
        <div className="ghibli-dust"></div>
        <div className="ghibli-dust"></div>
        <div className="ghibli-dust"></div>
        <div className="ghibli-dust"></div>
        <div className="ghibli-dust"></div>
      </>
    );
  };

  return (
    <div className="relative min-h-screen">
      {/* Render dust sprites */}
      {renderGhibliDustSprites()}
      
      <CommandPalette
        isOpen={isCommandPaletteOpen}
        setIsOpen={setIsCommandPaletteOpen}
        isDarkMode={isDarkMode}
        isGhibliMode={isGhibliMode}
        theme={theme}
        setTheme={setTheme}
        projects={PROJECTS}
      />
      
      {/* Preview Overlay */}
      <AnimatePresence>
        {activePreview && (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
            className="fixed left-[50%] top-1/2 -translate-y-1/2 z-40 pointer-events-none ml-24"
          >
            {activePreview.image?.url && (
              <motion.div 
                className="w-[280px]"
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                transition={{ type: "spring", duration: 0.5 }}
              >
                <div className={`
                  relative rounded-xl overflow-hidden
                  ${isDarkMode || isGhibliMode
                    ? 'bg-black/90 backdrop-blur-sm border border-white/[0.12] shadow-[0_8px_32px_-8px_rgba(0,0,0,0.5)]' 
                    : 'bg-white/90 backdrop-blur-sm border border-black/[0.08] shadow-[0_8px_32px_-8px_rgba(0,0,0,0.2)]'
                  }
                `}>
                  <img
                    src={activePreview.image.url}
                    alt={activePreview.title || 'Project preview'}
                    className="w-full h-full object-cover"
                  />
                  <div className="p-3">
                    {activePreview.title && (
                      <h3 className={`
                        text-xs font-medium
                        ${isDarkMode || isGhibliMode ? 'text-white' : 'text-gray-900'}
                      `}>
                        {activePreview.title}
                      </h3>
                    )}
                    {activePreview.description && (
                      <p className={`
                        text-[11px] mt-0.5 line-clamp-2
                        ${isDarkMode || isGhibliMode ? 'text-white/80' : 'text-gray-500'}
                      `}>
                        {activePreview.description}
                      </p>
                    )}
                  </div>
                </div>
              </motion.div>
            )}
          </motion.div>
        )}
      </AnimatePresence>

      <div className={`
        ${location.pathname.startsWith('/blog') ? 'max-w-2xl' : 'max-w-xl'} 
        mx-auto p-4 sm:p-6
        flex flex-col min-h-screen
        ${isGhibliMode ? 'text-white' : isDarkMode ? 'text-white' : 'text-gray-900'}
        transition-colors duration-300 ease-in-out
        ${fontFamily === 'inter' ? 'font-["Inter"] tracking-tight' : 'font-default tracking-normal'}
      `}>
        <div className="flex justify-center mb-8">
          <Nav 
            isDarkMode={isDarkMode}
            isGhibliMode={isGhibliMode}
            theme={theme}
            toggleTheme={toggleTheme}
            setTheme={setTheme}
            setIsCommandPaletteOpen={setIsCommandPaletteOpen}
          />
        </div>

        <div className="flex-1">
          <Routes>
            <Route path="/blog" element={<BlogList posts={posts} />} />
            <Route path="/blog/:slug" element={<BlogPostWrapper />} />
            <Route path="/" element={
              <>
                <header className="flex flex-col sm:flex-row sm:items-center gap-3 sm:gap-6 mt-8">
                  <div 
                    className="relative w-14 h-14 sm:w-16 sm:h-16 rounded-full overflow-hidden flex-shrink-0"
                    style={{ aspectRatio: '1 / 1' }}
                  >
                    <img 
                      src={isGhibliMode ? profilePicGhibli : isDarkMode ? profilePic : profilePicLight} 
                      alt="Prateek" 
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div>
                    <h1 className="text-base sm:text-l">
                      <span className="font-bold">Hi, I am Prateek.</span>{' '}
                      <span className={`font-normal ${isGhibliMode ? 'text-white/80' : isDarkMode ? 'text-gray-500' : 'text-gray-600'}`}>
                        I am a product marketer and creative based in Berlin, Germany.
                      </span>
                    </h1>
                  </div>
                </header>

                <main className="flex-1 overflow-y-auto space-y-6 sm:space-y-8 hide-scrollbar">
                  <Experience 
                    isDarkMode={isDarkMode}
                    isGhibliMode={isGhibliMode}
                    gygPreviewData={gygPreviewData}
                    setActivePreview={setActivePreview}
                  />

                  <motion.section className="mb-6">
                    <h2 className={`${isGhibliMode ? 'text-white/80' : 'text-gray-500'} text-sm mb-3`}>ABOUT</h2>
                    <p className="leading-relaxed text-sm">
                    I do marketing. I also like making, leading, and taking things from 0 to 1. I have a deep passion for good tech and design. I am often learning and experimenting with AI, code, design, photo, and film. I value high agency, creativity, and big-picture thinking.
                    </p>
                  </motion.section>

                  <motion.section>
                    <h2 className={`${isGhibliMode ? 'text-white/80' : 'text-gray-500'} text-sm mb-3`}>RECENT WORK</h2>
                    <ul className="space-y-3">
                      {PROJECTS.map((project, index) => (
                        <motion.li 
                          key={`${project.name}-${index}`}
                          className="cursor-pointer"
                        >
                          <ProjectCard 
                            project={project} 
                            isDarkMode={isDarkMode}
                            isGhibliMode={isGhibliMode}
                            onHoverStart={(previewData) => setActivePreview(previewData)}
                            onHoverEnd={() => setActivePreview(null)}
                            previewData={previews[project.link]}
                          />
                        </motion.li>
                      ))}
                    </ul>
                  </motion.section>
                </main>
              </>
            } />
          </Routes>
        </div>

        <Footer isDarkMode={isDarkMode} isGhibliMode={isGhibliMode} />
      </div>
    </div>
  );
}

export default App;
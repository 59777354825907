import React from 'react';
import { motion } from 'framer-motion';
import { 
  HiSun, 
  HiMoon,
  HiHome,
  HiDocumentText,
  HiPhoto
} from 'react-icons/hi2';
import { Link, useLocation } from 'react-router-dom';
import { THEMES } from '../contexts/ThemeContext';

const Nav = ({ isDarkMode, isGhibliMode, theme, toggleTheme, setTheme, setIsCommandPaletteOpen }) => {
  const location = useLocation();

  // Get theme icon based on current theme
  const getThemeIcon = () => {
    if (theme === THEMES.DARK) {
      return <HiSun className="w-3.5 h-3.5" />;
    } else if (theme === THEMES.LIGHT) {
      return <HiMoon className="w-3.5 h-3.5" />;
    } else {
      return <HiPhoto className="w-3.5 h-3.5" />;
    }
  };

  // Get theme text for tooltip
  const getThemeText = () => {
    if (theme === THEMES.DARK) {
      return "Light Mode";
    } else if (theme === THEMES.LIGHT) {
      return "Ghibli Theme";
    } else {
      return "Dark Mode";
    }
  };

  return (
    <motion.nav 
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      className="inline-flex"
    >
      <div className={`
        flex items-center gap-3 px-3 py-2 rounded-lg
        ${(isDarkMode || isGhibliMode)
          ? 'bg-black/40 text-gray-400' 
          : 'bg-white/40 text-gray-500'
        }
        backdrop-blur-xl border
        ${(isDarkMode || isGhibliMode)
          ? 'border-white/[0.08]' 
          : 'border-black/[0.08]'
        }
      `}>
        {/* Home link */}
        <Link 
          to="/"
          className={`
            text-xs hover:text-gray-300 transition-colors flex items-center gap-1.5
            ${location.pathname === '/' 
              ? (isDarkMode || isGhibliMode)
                ? 'text-white font-medium' 
                : 'text-gray-900 font-medium'
              : ''}
          `}
        >
          <HiHome className="w-3.5 h-3.5" />
          <span>Home</span>
        </Link>

        <div className="h-3 w-[1px] bg-current opacity-10" />

        {/* Blog link */}
        <Link 
          to="/blog"
          className={`
            text-xs hover:text-gray-300 transition-colors flex items-center gap-1.5
            ${location.pathname.startsWith('/blog')
              ? (isDarkMode || isGhibliMode)
                ? 'text-white font-medium' 
                : 'text-gray-900 font-medium'
              : ''}
          `}
        >
          <HiDocumentText className="w-3.5 h-3.5" />
          <span>Essays</span>
        </Link>

        <div className="h-3 w-[1px] bg-current opacity-10" />

        {/* Command palette */}
        <button 
          onClick={() => setIsCommandPaletteOpen(true)}
          className="flex items-center gap-1.5 text-sm hover:text-gray-300 transition-colors"
        >
          <kbd className={`
            hidden sm:inline px-1.5 py-0.5 text-[11px] rounded font-["Inter"]
            ${(isDarkMode || isGhibliMode)
              ? 'bg-white/[0.06]' 
              : 'bg-black/[0.03]'
            }
          `}>
            ⌘ + K
          </kbd>
        </button>

        <div className="hidden sm:block h-3 w-[1px] bg-current opacity-10" />

        {/* Theme toggle */}
        <button
          onClick={toggleTheme}
          className="p-1 rounded-full hover:bg-white/[0.06] transition-colors group relative"
        >
          {getThemeIcon()}
          <span className={`
            absolute -bottom-8 left-1/2 -translate-x-1/2 
            px-2 py-1 rounded text-[10px] whitespace-nowrap
            ${(isDarkMode || isGhibliMode) ? 'bg-white/10' : 'bg-black/5'}
            opacity-0 group-hover:opacity-100
            transition-opacity duration-200
            pointer-events-none
            hidden sm:block
          `}>
            {getThemeText()} (Press 'L')
          </span>
        </button>
      </div>
    </motion.nav>
  );
};

export default Nav;

import React, { createContext, useContext, useState, useEffect } from 'react';

// Theme options
export const THEMES = {
  DARK: 'dark',
  LIGHT: 'light',
  GHIBLI: 'ghibli'
};

const ThemeContext = createContext();

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme || THEMES.DARK;
  });
  const [isTransitioning, setIsTransitioning] = useState(false);

  const isDarkMode = theme === THEMES.DARK;
  const isGhibliMode = theme === THEMES.GHIBLI;

  const toggleTheme = () => {
    setIsTransitioning(true);
    // Cycle through themes: dark -> light -> ghibli -> dark
    if (theme === THEMES.DARK) {
      setTheme(THEMES.LIGHT);
    } else if (theme === THEMES.LIGHT) {
      setTheme(THEMES.GHIBLI);
    } else {
      setTheme(THEMES.DARK);
    }
    
    // Reset transition state after animation completes
    setTimeout(() => {
      setIsTransitioning(false);
    }, 600);
  };

  const setSpecificTheme = (newTheme) => {
    if (Object.values(THEMES).includes(newTheme)) {
      setIsTransitioning(true);
      setTheme(newTheme);
      
      // Reset transition state after animation completes
      setTimeout(() => {
        setIsTransitioning(false);
      }, 600);
    }
  };

  // Update localStorage and apply body classes when theme changes
  useEffect(() => {
    localStorage.setItem('theme', theme);
    
    document.body.style.transition = 'background-color 0.5s ease, color 0.5s ease';
    
    // Remove all theme classes
    document.body.classList.remove(THEMES.DARK, THEMES.LIGHT, THEMES.GHIBLI);
    
    // Add current theme class
    document.body.classList.add(theme);
    
    // Add a smooth fade transition class if switching to/from Ghibli
    if (isTransitioning) {
      document.body.classList.add('theme-transitioning');
      
      setTimeout(() => {
        document.body.classList.remove('theme-transitioning');
      }, 600);
    }
  }, [theme, isTransitioning]);

  return (
    <ThemeContext.Provider value={{ 
      theme, 
      isDarkMode, 
      isGhibliMode,
      isTransitioning,
      toggleTheme, 
      setTheme: setSpecificTheme
    }}>
      {children}
    </ThemeContext.Provider>
  );
}

export const useTheme = () => useContext(ThemeContext); 